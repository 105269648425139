/* eslint-disable max-len */

type CashmeFooterProps = {
  className?: string
}

export const CashmeFooter = ({ className }: CashmeFooterProps) => (
  <svg
    width="134"
    height="26"
    viewBox="0 0 134 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3138_72176)">
      <path
        d="M133.399 14.2276C133.399 8.86562 129.418 5.14746 123.671 5.14746C117.544 5.14746 113.287 9.38213 113.287 15.4611C113.287 21.5401 117.787 25.7713 124.231 25.7713C127.45 25.7713 130.358 24.71 132.538 22.868L129.968 18.3939C128.653 19.2819 126.68 20.3067 124.222 20.3067C121.593 20.3067 119.989 19.1616 119.68 17.5059H132.916C133.224 16.4393 133.386 15.3367 133.399 14.2276ZM119.653 13.3739C119.899 11.6357 121.58 10.4706 123.497 10.4706C125.436 10.4706 126.717 11.6663 126.717 13.3739H119.653Z"
        fill="white"
      />
      <path
        d="M98.9205 13.032L92.3428 1.31738H85.8345V25.2254H92.5508V12.5851L97.8818 21.7065H99.958L105.29 12.5025V25.2254H112.005V1.31738H105.497L98.9205 13.032Z"
        fill="white"
      />
      <path
        d="M35.7792 5.14258C32.4897 5.14258 29.1668 6.47512 26.5013 8.76286L29.6175 13.0659C31.3124 11.7381 33.1495 10.8124 34.8433 10.8124C36.574 10.8124 37.7514 11.7688 37.7514 13.3053V13.6473H33.5966C28.6469 13.6473 25.6348 15.7298 25.6348 19.5895C25.6348 23.1768 28.2309 25.7711 32.3128 25.7711C34.7703 25.7711 36.8478 24.8147 38.2319 23.2782V25.2251H44.4629V13.7487C44.4665 8.38668 40.9356 5.14258 35.7792 5.14258ZM37.7514 18.053C37.7514 19.9303 36.47 21.0919 34.6006 21.0919C33.2165 21.0919 32.4885 20.4433 32.4885 19.3843C32.4885 18.3254 33.1471 17.6768 34.254 17.6768H37.7514V18.053Z"
        fill="#00BFFF"
      />
      <path
        d="M13.2767 0.771484C9.22585 0.771484 5.7643 2.58163 3.37612 5.21134C2.64938 5.99672 2.40674 6.47549 2.40674 7.08988C2.40674 7.63587 2.6458 8.08045 3.02948 8.31983L7.39109 10.9837C7.65791 11.1308 7.89301 11.3279 8.08316 11.5639C8.77642 9.30922 10.9172 7.73846 13.4835 7.73846C15.5609 7.73846 17.465 8.72903 18.7117 10.7102L24.3534 7.19247C22.1732 3.36582 18.1224 0.771484 13.2767 0.771484Z"
        fill="#00BFFF"
      />
      <path
        d="M13.4837 18.8049C10.9222 18.8049 8.81131 17.2719 8.11804 15.0137C7.98058 15.1847 7.63992 15.3899 7.3913 15.5255L3.02851 18.1894C2.57908 18.463 2.40576 18.8733 2.40576 19.3509C2.40576 19.9311 2.68187 20.4783 3.37514 21.2979C5.76571 24.1281 9.32887 25.7719 13.2064 25.7719C18.1214 25.7719 22.171 23.1776 24.3524 19.3509L18.7107 15.8332C17.4652 17.8144 15.5611 18.8049 13.4837 18.8049Z"
        fill="#00BFFF"
      />
      <path
        d="M6.90705 14.7395C7.49513 14.3987 7.84176 13.9883 7.84176 13.2713C7.84176 12.5544 7.52979 12.144 6.90705 11.769L2.51078 9.14398C2.08556 8.86083 1.75916 8.45522 1.57607 7.98242C0.789477 9.63696 0.38724 11.4439 0.398708 13.2713C0.386476 15.0894 0.788858 16.8869 1.57607 18.5308C1.6452 18.2853 1.76355 18.0561 1.92411 17.8565C2.08466 17.657 2.28415 17.4913 2.51078 17.3692L6.90705 14.7395Z"
        fill="white"
      />
      <path
        d="M76.9723 5.14285C74.5818 5.14285 72.7494 6.16762 71.364 8.38696V1.31738H64.6489V25.2254H71.364V14.0226C71.364 12.3492 72.9574 10.8127 74.7575 10.8127C76.1416 10.8127 77.1803 11.769 77.1803 13.0662V25.2254H83.8954V13.0662C83.8954 8.14875 81.0232 5.14285 76.9723 5.14285Z"
        fill="#00BFFF"
      />
      <path
        d="M56.9981 13.0663L54.9219 12.281C54.0218 11.939 53.5365 11.7008 53.5365 11.2196C53.5365 10.7078 54.0218 10.4343 54.99 10.4343C56.41 10.4343 57.9328 11.0144 59.3181 12.176L62.641 8.00973C60.8409 6.19959 58.0368 5.14062 54.8179 5.14062C50.1096 5.14062 46.6827 7.42954 46.6827 11.3222C46.6827 14.4307 48.863 16.1383 52.9138 17.6748L55.2326 18.5628C56.0287 18.8706 56.41 19.1088 56.41 19.5534C56.41 20.1335 55.7872 20.5097 54.5752 20.5097C52.6711 20.5097 50.6977 19.5876 48.9323 17.9826L45.644 22.0121C47.7549 24.4024 50.975 25.7692 54.6087 25.7692C59.697 25.7692 63.2626 23.1052 63.2626 19.2833C63.2638 16.2774 61.0836 14.6029 56.9981 13.0663Z"
        fill="#00BFFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_3138_72176">
        <rect width="133.036" height="25" fill="white" transform="translate(0.398438 0.771484)" />
      </clipPath>
    </defs>
  </svg>
)
