/* eslint-disable max-len */

type IconBusinessProps = {
  className?: string
}

export const IconBusiness = ({ className }: IconBusinessProps) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_1891_136524)">
      <path
        d="M6 14.0061C6 10.8562 7.44665 8.39401 9.77801 6.68813C11.9132 5.1258 14.8074 4.19249 18 4.03121V23.0061H6V14.0061Z"
        stroke="#009FD4"
        strokeWidth="2"
      />
      <line x1="18" y1="3.0061" x2="18" y2="1.0061" stroke="#009FD4" strokeWidth="2" strokeLinecap="round" />
      <line x1="6" y1="19.0061" x2="14" y2="19.0061" stroke="#009FD4" strokeWidth="2" strokeLinecap="round" />
      <line x1="4" y1="23.0061" x2="20" y2="23.0061" stroke="#009FD4" strokeWidth="2" strokeLinecap="round" />
      <line x1="6" y1="15.0061" x2="14" y2="15.0061" stroke="#009FD4" strokeWidth="2" strokeLinecap="round" />
      <line x1="8" y1="11.0061" x2="14" y2="11.0061" stroke="#009FD4" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_1891_136524">
        <rect width="24" height="24" fill="white" transform="translate(0 0.00610352)" />
      </clipPath>
    </defs>
  </svg>
)
