/* eslint-disable max-len */

type CashmeMobileWhiteProps = {
  className?: string
}

export const CashmeMobileWhite = ({ className }: CashmeMobileWhiteProps) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M42.6574 0C29.8688 0 18.9405 5.79245 11.4008 14.2075C9.10649 16.7208 8.34045 18.2528 8.34045 20.2189C8.34045 21.966 9.09517 23.3887 10.3065 24.1547L24.0763 32.6792C24.9187 33.1497 25.6609 33.7805 26.2612 34.5358C28.4499 27.3208 35.2084 22.2943 43.3103 22.2943C49.8688 22.2943 55.8801 25.4641 59.8159 31.8038L77.6273 20.5472C70.7442 8.30189 57.9556 0 42.6574 0Z" fill="#00BFFF"/>
    <path d="M43.3103 57.7057C35.2235 57.7057 28.5593 52.8 26.3706 45.5736C25.9367 46.1208 24.8612 46.7774 24.0763 47.2113L10.3027 55.7359C8.88384 56.6113 8.33667 57.9246 8.33667 59.4529C8.33667 61.3095 9.20837 63.0604 11.397 65.683C18.9442 74.7396 30.1933 80 42.4348 80C57.9518 80 70.7367 71.6981 77.6235 59.4529L59.8121 48.1963C55.8801 54.5359 49.8687 57.7057 43.3103 57.7057Z" fill="#00BFFF"/>
    <path d="M22.548 44.6981C24.4046 43.6075 25.499 42.2943 25.499 40C25.499 37.7056 24.5141 36.3924 22.548 35.1924L8.66878 26.7924C7.32633 25.8863 6.29588 24.5884 5.71784 23.0754C3.23453 28.37 1.96465 34.1521 2.00085 40C1.96224 45.8178 3.23258 51.5698 5.71784 56.8302C5.93611 56.0447 6.30974 55.3111 6.81662 54.6726C7.3235 54.0342 7.95331 53.5039 8.66878 53.1132L22.548 44.6981Z" fill="white"/>
  </svg>
)
