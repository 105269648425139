/* eslint-disable react/require-default-props */
import { FC, useCallback } from 'react'

import { LogoProps } from './logo.model'
import { Wrapper } from './styles'
import * as SvgList from './svg'

export const Logo: FC<LogoProps> = ({ type, ariaLabel, className, width = 0, height = 0, ...rest }) => {
  const Component = SvgList[type]
  const MemoizedComponent = useCallback(
    ({ className: logoClassName }) => (
      <Component
        width={width}
        height={height}
        aria-label={ariaLabel}
        aria-hidden={!ariaLabel}
        className={logoClassName}
        {...rest}
      />
    ),
    [Component, width, height, ariaLabel, rest]
  )

  if (!!type) {
    return <Wrapper as={MemoizedComponent} width={width} height={height} className={className} type={type} />
  }

  return <></>
}
