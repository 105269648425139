/* eslint-disable max-len */

type IconLinkedinProps = {
  className?: string
}

export const IconLinkedin = ({ className }: IconLinkedinProps) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M18.9936 0.810547H2.20857C1.32107 0.810547 0.601074 1.53055 0.601074 2.41805V19.203C0.601074 20.0905 1.32107 20.8105 2.20857 20.8105H18.9936C19.8811 20.8105 20.6011 20.0905 20.6011 19.203V2.41805C20.6011 1.53055 19.8811 0.810547 18.9936 0.810547ZM18.9936 19.2105C7.79857 19.208 2.20107 19.2055 2.20107 19.203C2.20357 8.00805 2.20607 2.41055 2.20857 2.41055C13.4036 2.41305 19.0011 2.41555 19.0011 2.41805C18.9986 13.613 18.9961 19.2105 18.9936 19.2105ZM3.56607 8.30805H6.53357V17.853H3.56607V8.30805ZM5.05107 7.00305C5.99857 7.00305 6.77107 6.23305 6.77107 5.28305C6.77107 5.05717 6.72659 4.83351 6.64015 4.62483C6.55371 4.41615 6.42701 4.22654 6.2673 4.06682C6.10758 3.90711 5.91797 3.78041 5.70929 3.69397C5.50061 3.60754 5.27695 3.56305 5.05107 3.56305C4.8252 3.56305 4.60154 3.60754 4.39286 3.69397C4.18418 3.78041 3.99457 3.90711 3.83485 4.06682C3.67513 4.22654 3.54844 4.41615 3.462 4.62483C3.37556 4.83351 3.33107 5.05717 3.33107 5.28305C3.32857 6.23305 4.09857 7.00305 5.05107 7.00305ZM11.3586 13.1305C11.3586 11.8855 11.5961 10.6805 13.1386 10.6805C14.6586 10.6805 14.6811 12.103 14.6811 13.2105V17.853H17.6461V12.618C17.6461 10.048 17.0911 8.07055 14.0886 8.07055C12.6461 8.07055 11.6786 8.86305 11.2811 9.61305H11.2411V8.30805H8.39357V17.853H11.3586V13.1305Z"
      fill="#00BFFF"
    />
  </svg>
)
