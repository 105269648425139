/* eslint-disable max-len */

type IconYoutubeProps = {
  className?: string
}

export const IconYoutube = ({ className }: IconYoutubeProps) => (
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M24.1611 7.07729C23.91 6.05855 23.1676 5.24355 22.2287 4.96849C20.5365 4.47949 13.7021 4.47949 13.7021 4.47949C13.7021 4.47949 6.87872 4.47949 5.17559 4.97868C4.23669 5.24355 3.4943 6.05855 3.2432 7.07729C2.78467 8.94159 2.78467 12.8128 2.78467 12.8128C2.78467 12.8128 2.78467 16.6841 3.2432 18.5484C3.4943 19.5671 4.23669 20.3821 5.17559 20.6572C6.87872 21.1462 13.7021 21.1462 13.7021 21.1462C13.7021 21.1462 20.5365 21.1462 22.2287 20.647C23.1676 20.3719 23.91 19.5671 24.1611 18.5382C24.6196 16.6841 24.6196 12.8128 24.6196 12.8128C24.6196 12.8128 24.6196 8.94159 24.1611 7.07729Z"
      stroke="#00BFFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5186 15.9381C10.5186 16.2947 10.7085 16.6244 11.017 16.8032C11.3256 16.9821 11.706 16.9831 12.0155 16.8059L17.4743 13.6796C17.7854 13.5014 17.9773 13.1703 17.9773 12.8118C17.9773 12.4533 17.7854 12.1222 17.4743 11.9441L12.0155 8.81778C11.706 8.64054 11.3256 8.64154 11.017 8.82039C10.7085 8.99925 10.5186 9.3289 10.5186 9.68555V15.9381Z"
      stroke="#00BFFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
