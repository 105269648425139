/* eslint-disable max-len */

type IconInstagramProps = {
  className?: string
}

export const IconInstagram = ({ className }: IconInstagramProps) => (
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      clipRule="evenodd"
      d="M8.58669 3.43652H18.4233C21.1309 3.43652 23.3253 5.52911 23.3253 8.12142V17.5016C23.3253 20.0835 21.1309 22.1865 18.4124 22.1865H8.58669C5.86824 22.1761 3.67383 20.0835 3.67383 17.4912V8.12142C3.67383 5.52911 5.86824 3.43652 8.58669 3.43652Z"
      stroke="#00BFFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5008 16.9774C15.9126 16.9774 17.8678 15.1118 17.8678 12.8105C17.8678 10.5091 15.9126 8.64355 13.5008 8.64355C11.089 8.64355 9.13379 10.5091 9.13379 12.8105C9.13379 15.1118 11.089 16.9774 13.5008 16.9774Z"
      stroke="#00BFFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
