type CashmeProps = {
  className?: string
}

export const Cashme = ({ className }: CashmeProps) => (
  <svg
    width="118"
    height="22"
    viewBox="0 0 118 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_2082_143463)">
      <path
        d="M117.968 11.8412C117.968 7.12257 114.438 3.85059 109.34 3.85059C103.906 3.85059 100.129 7.57709 100.129 12.9266C100.129 18.2761 104.121 21.9995 109.837 21.9995C112.692 21.9995 115.271 21.0656 117.205 19.4446L114.925 15.5075C113.759 16.2889 112.009 17.1907 109.829 17.1907C107.497 17.1907 106.074 16.183 105.8 14.7261H117.54C117.813 13.7874 117.957 12.8172 117.968 11.8412ZM105.776 11.0898C105.994 9.56021 107.485 8.53492 109.186 8.53492C110.905 8.53492 112.042 9.58719 112.042 11.0898H105.776Z"
        fill="#00BFFF"
      />
      <path
        d="M87.3868 10.7893L81.5525 0.480469H75.7798V21.5195H81.737V10.396L86.4655 18.4229H88.3071L93.0366 10.3234V21.5195H98.9928V0.480469H93.22L87.3868 10.7893Z"
        fill="#00BFFF"
      />
      <path
        d="M31.3822 3.84668C28.4645 3.84668 25.5172 5.01932 23.1529 7.03253L25.9168 10.8192C27.4202 9.65074 29.0497 8.83611 30.552 8.83611C32.0872 8.83611 33.1315 9.67772 33.1315 11.0299V11.3308H29.4462C25.056 11.3308 22.3843 13.1635 22.3843 16.56C22.3843 19.7168 24.687 21.9998 28.3076 21.9998C30.4874 21.9998 32.33 21.1582 33.5577 19.806V21.5193H39.0845V11.4201C39.0877 6.70149 35.9558 3.84668 31.3822 3.84668ZM33.1315 15.2078C33.1315 16.8599 31.995 17.8821 30.3368 17.8821C29.1091 17.8821 28.4634 17.3113 28.4634 16.3794C28.4634 15.4475 29.0476 14.8768 30.0293 14.8768H33.1315V15.2078Z"
        fill="#00224B"
      />
      <path
        d="M11.4227 0C7.82967 0 4.75934 1.59292 2.64107 3.90708C1.99647 4.59821 1.78125 5.01953 1.78125 5.56019C1.78125 6.04066 1.99329 6.43189 2.33361 6.64255L6.20227 8.98679C6.43893 9.11618 6.64746 9.28963 6.81612 9.49736C7.43103 7.51321 9.32984 6.13094 11.6061 6.13094C13.4487 6.13094 15.1376 7.00264 16.2434 8.74604L21.2475 5.65047C19.3137 2.28302 15.7207 0 11.4227 0Z"
        fill="#00224B"
      />
      <path
        d="M11.6062 15.8695C9.33417 15.8695 7.46186 14.5205 6.84695 12.5332C6.72503 12.6837 6.42287 12.8642 6.20235 12.9836L2.33264 15.3278C1.934 15.5686 1.78027 15.9297 1.78027 16.35C1.78027 16.8606 2.02518 17.3421 2.64009 18.0633C4.76048 20.5539 7.92093 22.0005 11.3602 22.0005C15.7197 22.0005 19.3117 19.7174 21.2465 16.35L16.2424 13.2544C15.1377 14.9978 13.4488 15.8695 11.6062 15.8695Z"
        fill="#00224B"
      />
      <path
        d="M5.773 12.2919C6.29462 11.992 6.60208 11.6309 6.60208 10.9999C6.60208 10.369 6.32537 10.0079 5.773 9.67787L1.87361 7.36787C1.49644 7.1187 1.20693 6.76177 1.04453 6.3457C0.346844 7.80169 -0.00993238 9.39179 0.000240141 10.9999C-0.0106092 12.5998 0.346295 14.1816 1.04453 15.6283C1.10586 15.4123 1.21083 15.2105 1.35324 15.0349C1.49565 14.8594 1.67259 14.7135 1.87361 14.6061L5.773 12.2919Z"
        fill="#00BFFF"
      />
      <path
        d="M67.9194 3.84688C65.799 3.84688 64.1737 4.74868 62.9449 6.70169V0.480469H56.9888V21.5195H62.9449V11.661C62.9449 10.1885 64.3582 8.83632 65.9548 8.83632C67.1825 8.83632 68.1039 9.67792 68.1039 10.8194V21.5195H74.06V10.8194C74.06 6.49207 71.5124 3.84688 67.9194 3.84688Z"
        fill="#00224B"
      />
      <path
        d="M50.2026 10.8193L48.3611 10.1282C47.5627 9.82727 47.1323 9.61765 47.1323 9.19425C47.1323 8.74388 47.5627 8.50312 48.4215 8.50312C49.681 8.50312 51.0317 9.01369 52.2605 10.0359L55.2078 6.36954C53.6112 4.77661 51.1239 3.84473 48.2688 3.84473C44.0927 3.84473 41.0531 5.85897 41.0531 9.28454C41.0531 12.02 42.9869 13.5226 46.5799 14.8748L48.6367 15.6562C49.3428 15.9271 49.681 16.1367 49.681 16.5279C49.681 17.0385 49.1287 17.3695 48.0536 17.3695C46.3647 17.3695 44.6143 16.558 43.0484 15.1457L40.1318 18.6916C42.0041 20.7951 44.8603 21.9978 48.0833 21.9978C52.5966 21.9978 55.7591 19.6536 55.7591 16.2903C55.7602 13.6451 53.8264 12.1715 50.2026 10.8193Z"
        fill="#00224B"
      />
    </g>
    <defs>
      <clipPath id="clip0_2082_143463">
        <rect width="118" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
