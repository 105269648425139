/* eslint-disable max-len */

type IconFacebookProps = {
  className?: string
}

export const IconFacebook = ({ className }: IconFacebookProps) => (
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8.38454 3.43652H18.2103C20.9287 3.43652 23.1231 5.53025 23.1231 8.12396V17.5093C23.1231 20.0926 20.9287 22.1967 18.2103 22.1967H8.38454C5.66609 22.1863 3.47168 20.0926 3.47168 17.4988V8.12396C3.47168 5.53025 5.66609 3.43652 8.38454 3.43652Z"
      stroke="#00BFFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2051 13.8525H18.2097"
      stroke="#00BFFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2117 8.64355H17.3906C15.7338 8.64355 14.3906 9.9867 14.3906 11.6436V12.8102V22.1852"
      stroke="#00BFFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
